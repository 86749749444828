html {
    font-family: 'Roboto', sans-serif;
}
strong{
    font-weight: 600;
}
.clearfix::after {
    clear: both;
    content: '';
    display: table;
}

.header {
    /*height: 58px;*/
}

.header nav {
    background-color: white;
    -webkit-box-shadow: none;
    border-bottom: solid 1px #e8e8e8;
    box-shadow: none;
    height: 58px;
    left: 0;
    line-height: 58px;
    position: fixed;
    top: 0;
    z-index: 9000;
}

.header .nav-wrapper {
    height: 58px;
    padding: 7px;
}

.header .brand-logo {
    margin-top: 6px;
}

.header .right {
    height: 44px;
    line-height: 44px;
}

.header .right > * {
    display: inline-block;
    vertical-align: top;
}

.header .right .input-field input {
    border: solid 1px #e8e8e8 !important;
    border-radius: 6px;
    font-size: 13px;
    line-height: 100%;
    outline: none;
    padding: 13px 10px 14px;
    width: 280px;
}

.header .right .input-field button {
    background: none;
    border: none;
    cursor: pointer;
    height: 28px;
    left: auto;
    padding: 0;
    right: 10px;
    width: 28px;
}

.header .right .input-field button img {
    height: 28px;
    width: 28px;
}

.header .side-button {
    height: 44px;
    line-height: 44px;
    padding: 8px 8px 0;
}

.header:not(.inactive) a:not(.brand-logo) > :not(.active),
.header.inactive a > .active,
.header.inactive .right form {
    display: none;
}

.header:not(.inactive) a > .active,
.header.inactive a > :not(.active) {
    display: block;
}

.header .brand-logo a > img {
    display: block !important;
}

.header__line {
    background-color: #e8e8e8;
    border-bottom: solid 1px rgba(0,0,0,0.05);
    box-shadow: rgba(0, 0, 0, 0.2) 0 1px 2px 0;
    color: rgba(0, 0, 0, 0.5);
    font-size: 10px;
    margin-top: 58px;
    padding: 3px;
    text-align: center;
    text-transform: uppercase;
}

.background.valign-wrapper {
    background: transparent url('/images/background.jpg') no-repeat scroll center;
    background-size: cover;
    height: calc(100vh - 79px);
}

.owl-carousel .item {
    color: white;
    font-weight: 400;
    text-align: center;
}

.owl-carousel .item img {
    display: inline-block;
    height: 128px;
    width: 128px;
}

.owl-carousel .item h4 {
    font-size: 20px;
    line-height: 24px;
}

.owl-carousel .item h5 {
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
}

.owl-carousel .owl-dots {
    margin-top: 20px;
    text-align: center;
}

.owl-carousel .owl-dots .owl-dot {
    background-color: transparent;
    border: solid 1px white;
    border-radius: 100%;
    height: 8px;
    margin: 5px;
    transition: background-color 0.15s ease-in-out;
    width: 8px;
}

.owl-carousel .owl-dots .owl-dot.active {
    background-color: white;
}

.login-form {
    margin-top: 40px;
    position: relative;
    z-index: 2;
}

.input-field {
    position: relative;
}

.prefix {
    filter: saturate(0);
    height: 28px;
    left: 0.5rem;
    position: absolute;
    width: 28px !important;
}

label[for="user-picture"] svg path {
    fill: white;
    transition: fill 0.2s ease-out;
}

label[for="user-picture"]:hover svg path {
    fill: #8c8c8c;
}

.login-form input[name="USER_LOGIN"] + .prefix {
    opacity: 0.2;
}

.login-form input[name="USER_LOGIN"]:focus + .prefix {
    -webkit-filter: none;
    filter: none;
    opacity: 1;
}

.login-form input[name="USER_PASSWORD"]:focus + .prefix {
    -webkit-filter: brightness(0);
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='bright125'><feComponentTransfer><feFuncR type='linear' slope='1.25'/><feFuncG type='linear' slope='1.25'/><feFuncB type='linear' slope='1.25'/></feComponentTransfer></filter></svg>#bright125");
    filter: brightness(0);
}

.login-form input {
    background-color: white;
    border: solid 2px white;
    border-radius: 6px;
    display: block;
    font-size: 13px;
    margin-bottom: 16px;
    outline: none;
    padding: 13px 10px 13px 50px;
    width: 100%;
    -webkit-appearance: none;
}

.login-form input[name="USER_LOGIN"]:focus {
    border-color: #ef5350;
}

.login-form input[name="USER_PASSWORD"]:focus {
    border-color: #212121;
}

form input::-webkit-input-placeholder {
    color: rgba(0,0,0,0.5);
}
form input::-moz-placeholder {
    color: rgba(0,0,0,0.5);
}
form input:-ms-input-placeholder {
    color: rgba(0,0,0,0.5);
}
form input:-moz-placeholder {
    color: rgba(0,0,0,0.5);
}

form button {
    border: none;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    display: block;
    font-size: 12px;
    font-weight: 700;
    padding: 15px;
    text-transform: uppercase;
    transition: background-color 0.15s ease-in-out;
    width: 100%;
}

.login-form button:hover {
    background-color: #f44336 !important;
}

.login-form button:active {
    background-color: #e53935 !important;
}

/* --- sidenav --- */

.sidenav {
    background-color: #ebebeb;
    width: 280px;
    z-index: 9500;
}

.sidenav label {
    color: rgba(0,0,0,0.87);
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    height: 48px;
    line-height: 48px;
    padding: 0 14px;
}

.sidenav li > label:hover {
    background-color: rgba(0,0,0,0.05);
}

.sidenav a, .sidenav label {
    display: block;
    padding: 0 14px !important;
    position: relative;
}

.sidenav a > *, .sidenav label > * {
    display: inline-block;
    vertical-align: top;
}

.sidenav a img, .sidenav label img {
    margin: 10px 10px 0 0;
}

.sidenav a::after, .sidenav label::after {
    border: solid 2px transparent;
    border-bottom-color: #bababa;
    border-right-color: #bababa;
    content: '';
    display: block;
    height: 10px;
    position: absolute;
    right: 14px;
    top: 17px;
    transform: rotate(-45deg);
    transition: all 0.15s ease-in-out;
    width: 10px;
}

.sidenav li > input {
    display: none !important;
}

.sidenav li input ~ ol {
    display: block;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
}

.sidenav li input:checked + label {
    background-color: rgba(0,0,0,0.05);
}

.sidenav li input:checked + label::after {
    transform: rotate(45deg);
}

.sidenav li input:checked ~ ol {
    max-height: 4800px;
}

.sidenav .user-view {
    padding: 35px 0 0 !important;
    position: relative;
}

.sidenav .user-view .close {
    height: 28px;
    position: absolute;
    top: 8px;
    right: 8px;
}

.sidenav .user-view .close > * {
    display: inline-block;
    vertical-align: top;
}

.sidenav .user-view .close span {
    color: white;
    font-size: 9px;
    font-weight: bold;
    line-height: 28px;
    margin-right: 4px;
    text-transform: uppercase;
}

.sidenav .user-view .close img {
    cursor: pointer;
    height: 28px;
    transition: transform 0.15s ease-in-out;
    width: 28px;
}

.sidenav .user-view .close img:hover {
    transform: rotate(90deg);
}

.sidenav .user-view a:hover {
    background-color: rgba(255,255,255,0.25);
}

.sidenav .user-view a .badge {
    color: white;
    display: inline-block;
    float: none;
    font-size: 14px;
    font-weight: bold;
    height: 22px;
    min-width: 0;
    width: 22px;
}

.sidenav .user-view .user-fullname {
    color: white;
    display: inline-block;
    font-size: 14px;
}

.sidenav .user-view a {
    color: white;
}

.sidenav .user-view .user-fullname {
    margin-top: 10px;
}

.sidenav .user-view .user-fullname > div {
    line-height: 115%;
}

.sidenav .user-view .user-fullname div + div {
    font-size: 12px;
}

.sidenav ol {
    background-color: white;
    counter-reset: list;
    margin: 0;
    padding: 0;
}

.sidenav ol li {
    display: block;
    list-style-type: none;
    height: 48px;
    margin: 0;
    padding: 0;
    position: relative;
    text-transform: uppercase;
    width: 100%;
}

.sidenav ol li a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 9px;
    line-height: 1.333;
    padding: 0 32px 0 55px !important;
}

.sidenav ol li a::after {
    content: none !important;
}

.sidenav ol li::before {
    content: '0' counter(list);
    counter-increment: list;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    left: 20px;
    position: absolute;
    top: 0;
}

.sidenav ol li:nth-child(n+10)::before {
    content: counter(list);
}

/* --- welcome --- */

.welcome {
    min-height: calc(100vh - 79px);
}

.welcome .container {
    width: 90%;
}

.welcome p {
    font-size: 14px;
}

.welcome p.bold {
    font-weight: bold;
}

.welcome .btn {
    font-size: 12px;
    font-weight: 700;
}

.welcome .btn:first-of-type:hover {
    background-color: #e8e8e8 !important;
    color: #8c8c8c !important;
}

.welcome .btn + .btn {
    margin-left: 12px;
}

.welcome .btn + .btn:hover {
    background-color: white !important;
    color: #ef5350 !important;
}

/* --- start page --- */

.start-page .container {
    padding: 25px 0;
    width: 90%;
}

.start-page .user > * {
    text-align: center;
}

.start-page .user__photo {
    border-radius: 100%;
    font-size: 0;
    height: 150px;
    margin: 0 auto 20px;
    position: relative;
    width: 150px;
}

.start-page .user__photo img {
    height: 150px;
    width: 150px;
    border-radius: 100%;
}

.start-page .user__photo a {
    background-color: #ef5350;
    border-radius: 100%;
    bottom: 0;
    cursor: pointer;
    display: block;
    height: 44px;
    left: calc(50% + 31px);
    padding: 10px;
    position: absolute;
    transition: transform 0.15s ease-in-out;
    width: 44px;
}

.start-page .user__photo a:hover {
    transform: rotate(90deg);
}

.start-page .user__photo a svg {
    height: 24px;
    width: 24px;
}

.start-page .user__photo a svg path {
    fill: white;
}

.user__surname {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.125;
}

.user__name {
    font-size: 14px;
    line-height: 1.286;
}

.user__position {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.286;
}

.start-page .col.l2 > a {
    background-color: #ebebeb;
    display: block;
    height: 116px;
    padding-top: 28px;
    position: relative;
}

.start-page .col.l2 > a,
.start-page .col.l2 > a * {
    transition: all 0.25s ease-in-out;
}

.start-page .col.l2 > a:hover {
    background-color: #ef5350;
}

.start-page .col.l2 > a:hover img {
    filter: brightness(300%);
}

.start-page .col.l2 > a:hover div {
    color: white;
}

.start-page .col.l2 > a img {
    display: block;
    height: 36px;
    margin: 0 auto;
    width: 36px;
}

.start-page .col.l2 > a div {
    color: #515151;
    display: flex;
    flex-direction: column;
    font-size: 11px;
    font-weight: bold;
    height: 24px;
    justify-content: center;
    line-height: 12px;
    margin-top: 12px;
    padding: 0 12px;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
}

.start-page .col.l9.tiles .col {
    padding: 5px;
    position: relative;
}

.start-page .col.l9.tiles .col .new-messages {
    background-color: #ef5350;
    border: solid 2px #ebebeb;
    border-radius: 100%;
    color: #ebebeb;
    height: 22px;
    margin: 0;
    padding: 0;
    position: absolute;
    right: calc(50% - 29px);
    top: calc(50% - 32px);
    width: 22px;
}

.start-page p {
    font-size: 14px;
}

.start-page p.big {
    font-size: 24px;
    margin: 0;
}

.start-page p.mid {
    font-size: 16px;
    margin-top: 0;
}

.start-page .row {
    margin-bottom: 0;
}

.start-page .container-wrapper {
    background: url('/images/woman-photo.png') no-repeat scroll calc(100% - 5vw) 10px;
    background-size: 200px auto;
}

.start-page .container-wrapper p + a {
    border-radius: 6px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    width: 280px;
}

.start-page .container-wrapper p + a:hover {
    background-color: #e8e8e8 !important;
    color: #8c8c8c !important;
}

.start-page .wrapper__how-to-work {
    background-color: #8c8c8c;
}

.start-page .wrapper__how-to-work .container {
    padding-bottom: calc(25px - 0.75em);
}

.start-page .wrapper__how-to-work h5 {
    color: white;
    font-size: 24px;
    margin: 0 0 20px;
    text-align: center;
}

.start-page .wrapper__how-to-work .col {
    padding: 0.75em;
}

.start-page .wrapper__how-to-work .col > a {
    background: transparent none no-repeat scroll center top;
    background-size: 128px 128px;
    display: block;
    height: 170px;
    position: relative;
}

.start-page .wrapper__how-to-work .col:nth-of-type(1) > a {
    /*background-image: url('/images/how-to-work-1.jpg');*/
    background-image: url('/images/icons/registration.svg');
}

.start-page .wrapper__how-to-work .col:nth-of-type(2) > a {
    /*background-image: url('/images/how-to-work-2.jpg');*/
    background-image: url('/images/icons/education.svg');
}

.start-page .wrapper__how-to-work .col:nth-of-type(3) > a {
    /*background-image: url('/images/how-to-work-3.jpg');*/
    background-image: url('/images/icons/testing.svg');
}

.start-page .wrapper__how-to-work .col:nth-of-type(4) > a {
    /*background-image: url('/images/how-to-work-4.jpg');*/
    background-image: url('/images/icons/questions.svg');
}

.start-page .wrapper__how-to-work .col > a span {
    bottom: 10px;
    color: white;
    font-size: 12px;
    font-weight: 700;
    left: 0;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}

/* --- footer --- */

.footer {
    text-align: center;
}

.footer p {
    font-size: 14px;
    line-height: 1.429;
    margin: 20px auto;
    max-width: 50%;
    text-align: center;
    width: 100%;
}

.footer .btn {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 12px;
    width: 260px;
}

.footer .btn:hover {

    background-color: #e8e8e8 !important;
    color: #8c8c8c !important;
}

.footer h6 {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.25;
    margin-bottom: 12px;
}

.footer .container {
    margin-bottom: 20px;
    width: 90%;
}

.footer .container .row {
    margin-bottom: 0;
}

.footer .col > div {
    font-size: 14px;
    line-height: 1.429;
}

.footer small {
    color: #515151;
    display: block;
    font-size: 9px;
    line-height: 1.333;
    margin: 0 auto 20px;
    max-width: 50%;
    width: 100%;
}

/* --- learning --- */

.learning__header {
    align-items: center;
    background-color: #ebebeb;
    /*border-top: solid 1px #a8a8a8;*/
    box-shadow: rgba(0, 0, 0, 1) 0 1px 2px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 72px;
    justify-content: flex-start;
    padding: 0 20px;
}

.learning__header__arrow {
    border-radius: 100%;
    height: 24px;
    transition: background-color 0.15s ease-in-out;
    width: 24px;
}

.learning__header__arrow:hover {
    background-color: #d1d1d1;
}

.learning__header__arrow img {
    transition: opacity 0.15s ease-in-out;
}

.learning__header__arrow:hover img {
    opacity: 0.71;
}

.learning__header__caption {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.125;
    margin: 0 0 0 12px;
}

.learning .width-wrapper {
    margin: 12px auto 0;
    max-width: 90%;
    width: 100%;
}

.learning .interactive-image {
    margin: 40px 0 30px;
    width: 100%;
}

.learning .image-notes div {
    font-size: 14px;
    line-height: 28px;
    position: relative;
}

.learning .image-notes div::before {
    content: '\2013';
    margin: 0 1ch 0 calc(16px + 1ch);
}

.learning .image-notes div::after {
    border: solid 2px transparent;
    border-radius: 100%;
    content: '';
    display: block;
    height: 16px;
    left: 0;
    position: absolute;
    top: 6px;
    width: 16px;
}

.learning .image-notes div.green-circle::after {
    border-color: #37a447;
}

.learning .image-notes div.red-circle::after {
    border-color: #e53935;
}

.learning .container {
    width: 100%;
}

.learning .width-wrapper .areas {
    margin: 36px 0 0;
}

.learning .area a {
    display: block;
}

.learning .area a:first-of-type {
    background: transparent none no-repeat scroll center;
    background-size: cover;
    border: solid 2px #e53935;
    border-radius: 100%;
    color: #e53935;
    font-size: 72px;
    font-weight: bold;
    height: 200px;
    line-height: 200px;
    margin: 0 auto;
    text-align: center;
    width: 200px;
}

.learning .area:nth-of-type(1) a:first-of-type {
    background-image: url('/images/area1.png');
}

.learning .area:nth-of-type(2) a:first-of-type {
    background-image: url('/images/area2.png');
}

.learning .area:nth-of-type(3) a:first-of-type {
    background-image: url('/images/area3.png');
}

.learning .area:nth-of-type(4) a:first-of-type {
    background-image: url('/images/area4.png');
}

.learning .area:nth-of-type(5) a:first-of-type {
    background-image: url('/images/area5.png');
}

.learning .area.area__green a:first-of-type {
    border-color: #37a447;
    color: #37a447;
}

.learning .area a:last-of-type {
    color: #e53935;
    font-size: 12px;
    font-weight: bold;
    height: 28px;
    line-height: 1.167;
    margin: 10px auto 30px;
    max-width: 200px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}

.learning .area.area__green a:last-of-type {
    color: #37a447;
}

.blocks .row {
    margin-bottom: 0;
}

.block {
    color: white;
    padding: 48px 0 32px !important;
    text-align: center;
}

.block:first-of-type {
    background-color: #37a447;
}

.block:last-of-type {
    background-color: #e53935;
}

.block .block__title {
    font-size: 24px;
}

.block .block__note {
    font-size: 16px;
    margin: 0 auto 20px;
    width: 260px;
}

.block .btn {
    border-radius: 6px;
    font-size: 12px;
    font-weight: bold;
    width: 260px;
}

.block:first-of-type .btn {
    color: #37a447;
}

.block:last-of-type .btn {
    color: #e53935;
}

.buttons {
    background-color: #ebebeb;
    padding: 30px 0;
    text-align: center;
}

.btn {
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 8px 0 !important;
    font-size: 12px;
    font-weight: bold;
    margin: 0 10px;
    width: 260px;
}

.buttons .btn:hover {
    background-color: #ef5350 !important;
    color: white !important;
}

/* --- table in learning pages */

.table-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 10px;
    width: 100vw;
}

table {
    margin: 32px auto;
    min-width: 972px;
    width: 90%;
}

table th {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.143;
}

table th small {
    display: block;
    font-size: 11px;
    font-weight: 400;
    line-height: 1.273;
}

table td {
    font-size: 12px;
    line-height: 1.333;
}

table * {

    border: none;
    border-collapse: collapse;
}

table tr td:nth-of-type(-n+2) {
    font-size: 14px;
}

table tr td:first-of-type {
    font-weight: bold;
}

table td img {
    cursor: pointer;
    height: 28px;
    width: 28px;
}

table td, table th {
    padding-bottom: 9px;
    padding-top: 9px;
}

table tr th:nth-of-type(2),
table tr th:nth-of-type(3),
table tr th:nth-of-type(4) {
    width: 15%;
}

#photo-popup {
    background-color: rgba(0,0,0,0.65);
    height: 100vh;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: opacity 0.15s ease-in-out;
    width: 100vw;
    z-index: 10000;
}

body.show__photo-popup #photo-popup {
    opacity: 1;
    pointer-events: initial;
}

body.show__photo-popup {
    overflow: hidden;
}

#photo-popup .photo-container {
    background-color: white;
    height: 540px;
    position: relative;
    top: calc(-50vh - 300px);
    transition: top 0.15s ease-in-out;
    width: 540px;
}

body.show__photo-popup #photo-popup .photo-container {
    top: 0;
}

#photo-popup .photo-container img.photo {
    height: 100%;
    max-height: 540px;
    max-width: 540px;
    width: 100%;
}

#photo-popup .photo-container img.close {
    height: 28px;
    cursor: pointer;
    position: absolute;
    right: -32px;
    top: -32px;
    width: 28px;
}

/* --- thrifty manufacture --- */

.thrifty .container {
    padding: 8px 32px 32px;
    width: 100%;
}

.thrifty .container a {
    background: transparent none no-repeat scroll center;
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    height: 170px;
    justify-content: center;
    margin: 24px auto 0;
    max-width: 260px;
    padding: 0 25px;
    position: relative;
    width: 100%;
}

.thrifty .container .col:nth-of-type(1) a {
    background-image: url('/images/thrifty-manufacture-page/image1.jpg');
}

.thrifty .container .col:nth-of-type(2) a {
    background-image: url('/images/thrifty-manufacture-page/image2.jpg');
}

.thrifty .container .col:nth-of-type(3) a {
    background-image: url('/images/thrifty-manufacture-page/image3.jpg');
}

.thrifty a .title {
    font-size: 20px;
    line-height: 1.2;
    text-align: center;
}

.thrifty a .action {
    bottom: 0;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5;
    margin: 0 48px 12px 0;
    position: absolute;
    right: 0;
    text-transform: uppercase;
}

.thrifty a .action::after {
    background: transparent url('/images/icons/arrow-right.svg') no-repeat scroll center;
    background-size: contain;
    content: '';
    display: block;
    height: 20px;
    position: absolute;
    right: -32px;
    top: -2px;
    width: 20px;
}

/* --- safety measures --- */

.safety .container {
    padding: 8px 32px 32px;
    width: 100%;
}

.safety .container a {
    background: transparent none no-repeat scroll center;
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    height: 170px;
    justify-content: center;
    margin: 24px auto 0;
    max-width: 260px;
    padding: 0 25px;
    position: relative;
    width: 100%;
}

.safety .container .col:nth-of-type(1) a {
    background-image: url('/images/safety-measures-page/risk-1.jpg');
}

.safety .container .col:nth-of-type(2) a {
    background-image: url('/images/safety-measures-page/med-1.jpg');
}

.safety .container .col:nth-of-type(3) a {
    background-image: url('/images/safety-measures-page/karta-1.jpg');
}

.safety a .title {
    font-size: 20px;
    line-height: 1.2;
    text-align: center;
}

.safety a .action {
    bottom: 0;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5;
    margin: 0 48px 12px 0;
    position: absolute;
    right: 0;
    text-transform: uppercase;
}

.safety a .action::after {
    background: transparent url('/images/icons/arrow-right.svg') no-repeat scroll center;
    background-size: contain;
    content: '';
    display: block;
    height: 20px;
    position: absolute;
    right: -32px;
    top: -2px;
    width: 20px;
}

/* --- profile page --- */

.profile .container {
    margin-top: 30px;
    width: 90%;
}

.profile .user {
    margin: 0 auto;
    padding-bottom: 24px;
    position: relative;
    text-align: center;
    width: 175px;
}

.profile .user__photo {
    border-radius: 100%;
    height: 175px;
    margin: 0 auto 20px;
    width: 175px;
}

.profile .user form {
    border-radius: 100%;
    display: block;
    height: 44px;
    position: absolute;
    right: 0;
    top: 145px;
    width: 44px;
}

.profile .user form label {
    border-radius: 100%;
    font-size: 36px;
    font-weight: 300;
    height: 44px;
    line-height: 44px;
    padding: 0;
    text-align: center;
    width: 44px;
}

.profile .user form label:hover {
    background-color: #e8e8e8 !important;
    color: #8c8c8c !important;
}

.profile .user form input {
    display: none;
}

.profile .password {
    background-color: #e8e8e8;
    padding: 14px 18px 24px;
}

.profile label {
    color: #515151;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.143;
    margin-bottom: 6px;
}

.profile .input-field {
    margin: 0 0 20px;
}

.profile .password input {
    background-color: white;
    border: solid 2px white;
    border-radius: 6px;
    display: block;
    font-size: 13px;
    margin: 0;
    outline: none;
    padding: 13px 10px 13px 40px;
    width: 100%;
    -webkit-appearance: none;
}

.profile .password input:focus + .prefix,
.profile .password input:not(:placeholder-shown) + .prefix {
    -webkit-filter: brightness(0);
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='bright125'><feComponentTransfer><feFuncR type='linear' slope='1.25'/><feFuncG type='linear' slope='1.25'/><feFuncB type='linear' slope='1.25'/></feComponentTransfer></filter></svg>#bright125");
    filter: brightness(0);
}

.profile .password button:hover {
    opacity: 0.9;
}

.profile .user,
.profile .about {
    padding-top: 14px;
}

.profile .about textarea {
    border: solid 2px #ebebeb;
    border-radius: 6px;
    color: rgba(0,0,0,0.5);
    font-size: 12px;
    height: 175px;
    line-height: 1.333;
    outline: none;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 12px;
    resize: none;
    -webkit-appearance: none;
}

.profile .about button {
    margin: 10px auto 0;
    max-width: 260px;
    width: 100%;
}

.profile .about button:hover {
    background-color: #e8e8e8 !important;
    color: #8c8c8c;
    margin: 10px auto 0;
    max-width: 260px;
    width: 100%;
}

.rating-and-statisctics {
    display: none;
    margin: 0 auto;
    width: 255px;
}

.rating-and-statisctics__rating {
    background: transparent url('/images/rating.png') no-repeat scroll center;
    background-size: cover;
    cursor: pointer;
    display: block;
    height: 116px;
    margin: 0 auto;
    position: relative;
    width: 255px;
}

.rating-and-statisctics__rating img {
    height: 64px;
    left: 8px;
    position: absolute;
    top: 27px;
    width: 64px;
}

.rating-and-statisctics__rating div {
    color: white;
    font-size: 14px;
    left: 90px;
    position: absolute;
    top: 37px;
}

.rating-and-statisctics__rating div strong {
    display: block;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.208;
    text-decoration: underline;
}

.rating-and-statisctics__statistics {
    display: block;
    padding: 12px 0;
}

.rating-and-statisctics__statistics > * {
    display: inline-block;
    vertical-align: top;
}

.rating-and-statisctics__statistics img {
    height: 24px;
    margin-right: 10px;
    width: 24px;
}

.rating-and-statisctics__statistics span {
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
}

.rating-and-statisctics__buttons {
    padding-bottom: 10px;
}

.rating-and-statisctics__buttons a {
    background-color: #f4f4f4;
    border-radius: 6px;
    box-shadow: rgba(0,0,0,0.2) 0 4px 8px 0;
    color: #e53935;
    display: block;
    font-size: 12px;
    font-weight: bold;
    height: 44px;
    line-height: 44px;
    margin: 0 auto 10px;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.15s ease-in-out;
    width: 255px;
}

.rating-and-statisctics__buttons a:hover {
    color: #8c8c8c;
}

.rating-and-statisctics__buttons a:active {
    background-color: #e53935;
    color: white;
}

/* --- learning specification pages --- */

.learning__spec {
    /**/
}

.learning__spec .tabs {
    background-color: #ebebeb;
    height: 48px;
    text-align: center;
}

.learning__spec .tabs a {
    box-sizing: border-box;
    display: inline-block;
    height: 48px;
    position: relative;
    transition: color 0.15s ease-in-out;
    vertical-align: top;
    width: 93px;
}

.learning__spec .tabs a img {
    -webkit-filter: brightness(0);
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='bright125'><feComponentTransfer><feFuncR type='linear' slope='1.25'/><feFuncG type='linear' slope='1.25'/><feFuncB type='linear' slope='1.25'/></feComponentTransfer></filter></svg>#bright125");
    filter: brightness(0);
    height: 24px;
    opacity: 0.87;
    transition: all 0.15s ease-in-out;
    width: 24px;
}

.learning__spec .tabs a div {
    bottom: -2px;
    color: #212121;
    display: block;
    font-size: 8px;
    font-weight: bold;
    height: 24px;
    left: 0;
    letter-spacing: 2px;
    line-height: 24px;
    position: absolute;
    width: 100%;
}

.learning__spec .tabs a.active {
    border-bottom: solid 2px #e53935;
    pointer-events: none;
}

.learning__spec .tabs a.active img,
.learning__spec .tabs a:hover img {
    -webkit-filter: none;
    filter: none;
    opacity: 1;
}
.learning__spec .tabs a.active div,
.learning__spec .tabs a:hover div {
    color: #e53935;
}

.learning__spec .tabs a.active div {
    bottom: -4px;
}

.learning__spec > img {
    display: block;
    margin: 20px auto;
    width: 100%;
}

.learning__spec .container {
    width: 90%;
}

.learning__spec .list-item {
    color: #2a8039;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 20px;
    max-width: 85%;
    min-height: 36px;
    padding-left: 30px;
    position: relative;
    text-transform: uppercase;
}

.learning__spec .list-item::before {
    border: solid 2px #2a8039;
    border-radius: 100%;
    content: '';
    display: block;
    height: 16px;
    left: 0;
    position: absolute;
    top: 0;
    width: 16px;
}

.learning__spec .list-item.red-item {
    color: #e53935;
}

.learning__spec .list-item.red-item::before {
    border-color: #e53935;
}

.learning__spec .nav_buttons {
    margin-bottom: 24px;
    text-align: center;
}

.learning__spec .nav_buttons a {
    border-radius: 6px;
    display: inline-block;
    transition: background-color 0.15s ease-in-out;
    width: 120px;
}

.learning__spec .nav_buttons a + a {
    margin-left: 25px;
}

.learning__spec .nav_buttons a > * {
    display: inline-block;
    vertical-align: top;
}

.learning__spec .nav_buttons a img {
    height: 20px;
    margin-top: 8px;
    margin-right: 8px;
    transform: scaleX(-1);
    transition: all 0.15s ease-in-out;
    width: 20px;
}

.learning__spec .nav_buttons a span + img {
    margin-left: 8px;
    margin-right: 0;
    transform: none;
}

.learning__spec .nav_buttons a span {
    font-size: 12px;
    font-weight: bold;
    height: 36px;
    line-height: 36px;
    text-transform: uppercase;
    transition: color 0.15s ease-in-out;
}

.learning__spec .nav_buttons a:hover {
    background-color: #ef5350 !important;
}

.learning__spec .nav_buttons a:hover img {
    -webkit-filter: brightness(1);
    filter: brightness(10);
}

.learning__spec .nav_buttons a:hover span {
    color: white;
}

.learning__spec .text-article {
    margin: 30px auto 40px;
    max-width: calc(100% - 120px);
    width: 100%;
}

.learning__spec .text-article p,
.learning__spec .text-article li {
    color: #212121;
    font-size: 14px;
    line-height: 1.143;
}

.learning__spec .text-article p b {
    font-weight: bold;
}

.learning__spec .text-article img {
    display: block;
    margin: 0 auto;
    /*max-height: 424px;*/
}

.learning__spec .text-article li {
    list-style-type: none;
}

.learning__spec .text-article li::before {
    /*content: '\2013';*/
    /*margin-right: 1ch;*/
}

.learning__spec .text-list {
    color: #212121;
    margin: 0 auto 40px;
    max-width: calc(100% - 120px);
    width: 100%;
}

.learning__spec .text-list h3 {
    font-size: 24px;
    font-weight: normal;
    margin: 20px 0 -4px;
}

.learning__spec .text-list p,
.learning__spec .text-list li {
    font-size: 14px;
    line-height: 1.143;
}

/* --- blocks in 3 columns --- */

.blocks-3-col {
    margin: 24px auto;
    width: 90%;
}

.blocks-3-col .col {
    margin-bottom: 36px;
}

.blocks-3-col a.block-green img {
    border-color: #37a447;
}

.blocks-3-col a.block-green div {
    color: #37a447;
}

.blocks-3-col a img {
    border: solid 5px #e53935;
    height: auto;
    width: 100%;
}

.blocks-3-col a div {
    color: #e53935;
    font-size: 12px;
    line-height: 1.429;
    margin-top: 10px;
    text-align: center;
    text-decoration: underline;
    text-transform: uppercase;
}

/* --- test --- */

.test, .test-result {
    border-top: solid 1px #666;
}

.test.no-bottom-buttons + div.buttons {
    display: none !important;
}

.learning__header {
    box-shadow: none;
}

.test-page .container {
    max-width: calc(100% - 200px);
    width: 100%;
}

.test-page .row {
    margin-bottom: 0;
    margin-top: 20px;
}

.test-page .row > .col {
    min-height: 500px;
}

.test-page .row > .col:first-of-type {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
}

.test-page .row > .col:last-of-type {
    background: transparent url('/images/woman-photo-2.png') no-repeat scroll right bottom;
    background-size: auto 100%;
}

.test-page p {
    color: #515151;
    font-size: 12px;
    line-height: 1.333;
}

.test-page p:first-of-type {
    position: relative;
}

.test-page p:first-of-type::before {
    background: transparent url('/images/icons/exclamation.svg') no-repeat scroll center;
    content: '';
    display: block;
    height: 24px;
    left: -40px;
    position: absolute;
    top: 0;
    width: 24px;
}

.test-page p b {
    font-size: 14px;
    font-weight: bold;
}

.test-page a.btn {
    border-radius: 6px;
    font-size: 12px;
    font-weight: bold;
    margin-top: 10px;
    width: 260px;
}

.test-questions {
    margin: 20px auto;
    max-width: calc(100% - 100px);
    width: 100%;
}

.test-questions__info > div {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    margin-right: 30px;
    vertical-align: top;
}

.test-questions__info__number::before,
.test-questions__info__time::before {
    background: transparent none no-repeat scroll center;
    content: '';
    display: inline-block;
    height: 24px;
    margin-right: 10px;
    vertical-align: top;
    width: 24px;
}

.test-questions__info__number::before {
    background-image: url('/images/icons/test-question-number.svg');
}

.test-questions__info__time::before {
    background-image: url('/images/icons/test-question-time.svg');
}

.test-question__text {
    color: #515151;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.143;
    margin-top: 12px;
}

.test-question__note {
    color: #515151;
    font-size: 12px;
    line-height: 1.333;
    margin-top: 5px;
}

.test-question__answers {
    margin: 20px 0 25px;
}

.test-question__answer {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    min-height: 40px;
    padding-bottom: 10px;
    justify-content: center;
}

.test-question__answer input {
    display: none !important;
}

.test-question__answer label {
    color: #515151;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    line-height: 16px;
    padding-left: 32px;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.test-question__answer label::before {
    background-color: transparent;
    border: solid 2px #5e5e5e;
    border-radius: 100%;
    content: '';
    display: block;
    height: 16px;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.15s ease-in-out;
    width: 16px;
}

.test-question__answer input:checked + label::before {
    background-color: #e53935;
    border-color: #e53935;
}

.test-question__answer label::after {
    background-color: transparent;
    border: solid 1.5px white;
    border-top: none !important;
    border-right: none !important;
    content: '';
    height: 7px;
    opacity: 0;
    left: 1.5px;
    position: absolute;
    top: 3px;
    transform: rotate(-45deg);
    transition: opacity 0.15s ease-in-out;
    width: 12px;
}

.test-question__answer input:checked + label::after {
    opacity: 1;
}

.test-question__buttons {
    background-color: #e8e8e8;
    margin: 0 -50px;
    padding: 22px 50px;
}

.test-question__buttons a {
    border-radius: 6px;
    font-size: 12px;
    font-weight: bold;
    min-width: 120px;
}

.test-question__buttons a + a {
    margin-left: 20px;
}

.test-question__buttons a img {
    filter: brightness(500%);
    height: 20px;
    left: 6px;
    position: relative;
    top: 6px;
    width: 20px;
}

.test-page a.btn:hover,
#js-btn-next:hover {
    background-color: white !important;
    color: #ef5350 !important;
}

#js-btn-end:hover {
    background-color: #ef5350 !important;
    color: white !important;
}

#js-btn-next:hover img {
    filter: none;
}

.test-question__answers ol li .select-wrapper {
    display: inline-block;
    vertical-align: top;
    width: 244px;
}

.test-question__answers ol li .select-wrapper input.select-dropdown {
    border-bottom: solid 2px #515151;
    color: #515151;
    font-size: 14px;
    height: 24px;
}

.test-question__answers ol li .select-wrapper svg {
    margin: 0;
}

.test-question__answers ol li .select-wrapper svg path:first-of-type {
    fill: #e53935;
}

.test-question__answers ol > li + li {
    margin-top: 24px;
}

.test-question__answers ol,
.test-question__answers ol > li {
    list-style-type: none;
}

.test-question__answers ol {
    counter-reset: x;
    padding-left: 0;
}

.test-question__answers ol > li::before {
    color: #515151;
    content: counter(x) ' \2013 ';
    counter-increment: x;
    display: inline-block;
    font-size: 14px;
    line-height: 25px;
    vertical-align: top;
}

.test-question__answers ol li .select-wrapper li {
    min-height: 30px;
}

.test-question__answers ol li .select-wrapper li:first-of-type {
    display: none !important;
}

.test-question__answers ol li .select-wrapper li span {
    color: #e53935;
    font-size: 14px;
    line-height: 20px;
    padding: 5px 10px;
}

.test-question img {
    max-width: 100%;
}

/* --- test result --- */

.test-result .container {
    margin: 0 auto;
    max-width: calc(100% - 100px);
    width: 100%;
}

.test-result .row {
    margin-bottom: 0;
    margin-top: 20px;
}

.test-result .col {
    min-height: 500px;
}

.test-result .col:first-of-type {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
}

.test-result .col:last-of-type {
    background: transparent url('/images/woman-photo-2.png') no-repeat scroll center bottom;
    background-size: auto 100%;
}

.test-result__image {
    height: 64px;
    margin-bottom: 12px;
    width: 64px;
}

.test-result__text {
    color: #e53935;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    text-align: center;
}

.test-result__view-answers {
    border-radius: 6px;
    font-size: 12px;
    font-weight: bold;
    margin: 12px 0;
    width: 260px;
}

.test-result__view-answers:hover {
    background-color: #e53935 !important;
    color: white !important;
}

.test-result__details {
    padding-left: 32px;
    position: relative;
    width: 260px;
}

.test-result__details::before {
    background: transparent url('/images/icons/info.svg') no-repeat scroll center;
    content: '';
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;
}

.test-result__details > div {
    height: 16px;
}

.test-result__details > div > * {
    color: #212121;
    display: inline-block;
    /*vertical-align: top;*/
}

.test-result__details > div > span {
    font-size: 12px;
    line-height: 1.333;
    margin-right: 4px;
}

.test-result__details > div > span::after {
    content: ':';
    font-size: 12px;
    line-height: 1.333;
}

.test-result__details > div > b {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.143;
}

.test-result__once-more {
    color: #e53935;
    font-size: 12px;
    font-weight: bold;
    line-height: 12px;
    margin-top: 12px;
    padding-left: 32px;
    text-decoration: underline;
    width: 260px;
}

/* --- testing --- */

.testing {
    /**/
}

.testing__label {
    padding: 14px 0;
    text-align: center;
}

.testing__label > * {
    display: inline-block;
    vertical-align: top;
}

.testing__label span {
    color: #e53935;
    line-height: 24px;
    margin-left: 12px;
    text-transform: uppercase;
}

.testing__label img {
    color: #e53935;
    height: 24px;
    width: 24px;
}

.testing .container {
    margin: 0 auto;
    max-width: calc(100% - 100px);
    width: 100%;
}

.testing .testing-item {
    box-shadow: rgba(0, 0, 0, 0.2) 0 4px 8px 0;
    margin-bottom: 30px;
}

.testing .testing-item__main {
    background: transparent none no-repeat scroll center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 170px;
    justify-content: space-around;
    padding: 12px;
    position: relative;
}

.testing .testing-item__main > * {
    position: relative;
    z-index: 2;
}

.testing .testing-item__backdrop {
    background-color: black;
    height: 100%;
    left: 0;
    opacity: 0.5;
    pointer-events: none;
    position: absolute;
    right: 0;
    transition: all 0.15s ease-in-out;
    width: 100%;
    z-index: 1;
}

.testing .testing-item:hover .testing-item__backdrop {
    background-color: #e53935;
    opacity: 0.85;
}

.testing .testing-item__note {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 44px;
    justify-content: center;
}

.testing .testing-item__note > span {
    color: #212121;
    display: inline-block;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
}

.testing .testing-item__percent {
    color: #ffea00;
    font-size: 24px;
    font-weight: bold;
    line-height: 22px;
    text-align: right;
}

.testing-item__percent--none {
    visibility: hidden;
}

.testing .testing-item__percent.level1::after {
    background: transparent url('/images/icons/yellow/level1.svg') no-repeat scroll center;
    content: '';
    display: inline-block;
    height: 22px;
    margin-left: 8px;
    width: 22px;
}

.testing .testing-item__percent.level2::after {
    background: transparent url('/images/icons/yellow/level2.svg') no-repeat scroll center;
    content: '';
    display: inline-block;
    height: 22px;
    margin-left: 8px;
    width: 22px;
}

.testing .testing-item__percent.level3::after {
    background: transparent url('/images/icons/yellow/level3.svg') no-repeat scroll center;
    content: '';
    display: inline-block;
    height: 22px;
    margin-left: 8px;
    width: 22px;
}

.testing .testing-item__percent.level4::after {
    background: transparent url('/images/icons/yellow/level4.svg') no-repeat scroll center;
    content: '';
    display: inline-block;
    height: 22px;
    margin-left: 8px;
    width: 22px;
}

.testing .testing-item__title {
    color: white;
    font-size: 20px;
    line-height: 1.2;
    text-align: center;
}

.testing .testing-item__start {
    color: #ffea00;
    font-size: 12px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 1px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    transition: color 0.15s ease-in-out;
}

.testing .testing-item__start::after {
    background: transparent url('/images/icons/arrow-right-yellow.svg') no-repeat scroll center;
    content: '';
    display: block;
    height: 24px;
    position: absolute;
    right: 0;
    top: 0;
    transition: filter 0.15s ease-in-out;
    width: 24px;
}

.testing .testing-item:hover .testing-item__start {
    color: white;
}

.testing .testing-item:hover .testing-item__start::after {
    filter: brightness(500%);
}

.testing .testing-item__start:hover {
    opacity: 1;
}

/* --- test answers page --- */

.test-answers .container {
    width: 90%;
}

.test-answers .row {
    background: transparent url('/images/woman-photo.png') no-repeat scroll 85% bottom;
    margin-bottom: 0;
    min-height: 700px;
    padding-bottom: 20px;
}

.test-answer {
    margin: 20px 0;
    padding-left: 44px;
    position: relative;
}

.test-answer::before {
    background: transparent url('/images/icons/smile-sad.svg') no-repeat scroll center;
    content: '';
    display: block;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;
}

.test-answer.test-answer--correct::before {
    background-image: url('/images/icons/smile-normal.svg');
}

.test-answer__answer {
    margin-top: 10px;
}

.test-answer__question,
.test-answer__answer b {
    color: #212121;
    display: block;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.143;
}

.test-answer__answer div {
    font-size: 12px;
    line-height: 1.333;
}

.test-info {
    border: solid 3px #e53935;
    box-sizing: border-box;
    margin: 25px auto 0;
    padding: 20px 0 25px 64px;
    width: 300px;
}

.test-info__items {
    margin-bottom: 10px;
    position: relative;
}

.test-info__items::before {
    background: transparent url('/images/icons/info.svg') no-repeat scroll center;
    content: '';
    display: block;
    height: 24px;
    left: -40px;
    position: absolute;
    top: 4px;
    width: 24px;
}

.test-info__item {
    height: 16px;
}

.test-info__item > span {
    color: #212121;
    font-size: 12px;
    line-height: 1.333;
    margin-right: 1ch;
}

.test-info__item > b {
    color: #212121;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.333;
}

.test-info__once-more {
    color: #e53935;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    text-decoration: underline;
}

/* --- statistics page --- */

.statistics {
}

.statistics .container {
    width: 90%;
}

.statistics .row,
.rating .row {
    background: transparent url('/images/woman-photo.png') no-repeat scroll 85% bottom;
    margin-bottom: 0;
    min-height: 550px;
    padding-bottom: 20px;
}

.statistics ul li {
    display: block;
    margin-top: 14px;
    min-height: 52px;
}

.statistics ul li > * {
    color: #212121;
    display: block;
    padding-top: 4px;
}

.statistics ul li > b {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.143;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.statistics ul li > span {
    font-size: 12px;
    font-weight: normal;
    line-height: 1.333;
}

.statistics__icon {
    float: right;
    width: 46px;
}

.statistics__icon img {
    display: block;
    height: 32px;
    margin: 0 auto;
    width: 32px;
}

.statistics__icon b {
    color: #e53935;
    display: block;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.143;
    margin-top: 4px;
    text-align: center;
    width: 46px;
}

/* --- rating page --- */

.rating .container {
    width: 90%;
}

.rating table,
.rating table tr {
    min-width: 0;
    width: 100%;
}

.rating table, .rating table * {
    border: none;
    border-collapse: collapse;
    border-radius: 0;
}

.rating table tr.active {
    background-color: #d1d1d1;
}

.rating table td {
    color: #212121;
    text-align: center;
    width: 50px;
}

.rating table td:nth-of-type(2) {
    text-align: left;
    width: auto;
}

.rating table td:nth-of-type(2) div {
    font-size: 12px;
    font-weight: normal;
    line-height: 1.333;
}

.rating table td:nth-of-type(2) div:first-of-type {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.143;
}

.rating table td:nth-of-type(1) {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.143;
}

.rating table td:nth-of-type(4) {
    color: #e53935;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.143;
}

.rating table tr.active td:nth-of-type(1) {
    color: #e53935;
}

/* --- FAQ page --- */

.faq, .text-page {
    margin: 40px auto;
    max-width: 90%;
    width: 100%;
}

.faq-item {
    color: #212121;
}

.faq-item + .faq-item {
    margin-top: 24px;
}

.faq-item__question {
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
}

.faq-item__answer {
    font-size: 12px;
    font-weight: normal;
    line-height: 1.333;
    margin-top: 10px;
}

/* --- text page --- */

.text-page {
    color: #212121;
}

.text-page h1 {
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    margin-bottom: 16px;
}

.text-page p {
    font-size: 12px;
    font-weight: normal;
    line-height: 1.333;
}

/* --- corporate ladder page --- */

.corporate-ladder {
    height: 300px;
    margin: 40px auto;
    max-width: 950px;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: 100%;
}

.corporate-ladder > .person {
    background: transparent url('/images/icons/person.svg') no-repeat scroll center top;
    background-size: 28px 32px;
    color: #212121;
    font-size: 11px;
    font-weight: normal;
    height: 63px;
    line-height: 12px;
    padding-top: 39px;
    position: absolute;
    text-align: center;
    width: 85px;
}

.corporate-ladder > .person.me {
    background-image: url('/images/icons/person-red.svg');
    color: #e53935;
}

.corporate-ladder > .person.h1 {left: 0}
.corporate-ladder > .person.h2 {left: calc((100% - 85px) / 6)}
.corporate-ladder > .person.h3 {left: calc((100% - 85px) / 3)}
.corporate-ladder > .person.h4 {left: calc(50% - 42.5px)}
.corporate-ladder > .person.h5 {left: calc((100% - 85px) / 3 * 2)}
.corporate-ladder > .person.h6 {left: calc((100% - 85px) / 6 * 5)}
.corporate-ladder > .person.h7 {left: calc(100% - 85px)}
.corporate-ladder > .person.v1 {top: 0}
.corporate-ladder > .person.v2 {top: calc(50% - 31.5px)}
.corporate-ladder > .person.v3 {top: calc(100% - 63px)}

.corporate-ladder > .arrow {
    background-color: #bababa;
    height: 2px;
    position: absolute;
    width: 24px;
}

.corporate-ladder > .arrow::before,
.corporate-ladder > .arrow::after {
    border-top: solid 2px #bababa;
    content: '';
    display: none;
    height: 12px;
    position: absolute;
    top: 1px;
    width: 12px;
}

.corporate-ladder > .arrow::before {
    border-left: solid 2px #bababa;
    left: -1px;
    transform: rotate(-45deg);
    transform-origin: left top;
}

.corporate-ladder > .arrow::after {
    border-right: solid 2px #bababa;
    right: -1px;
    transform: rotate(45deg);
    transform-origin: right top;
}

.corporate-ladder > .arrow.right::after,
.corporate-ladder > .arrow.right-down::after,
.corporate-ladder > .arrow.right-up::after,
.corporate-ladder > .arrow.up::after,
.corporate-ladder > .arrow.down::after,
.corporate-ladder > .arrow.up-down::before,
.corporate-ladder > .arrow.up-down::after {
    display: block;
}

.corporate-ladder > .arrow.right-down {
    transform: rotate(45deg);
}

.corporate-ladder > .arrow.right-up {
    transform: rotate(-45deg);
}

.corporate-ladder > .arrow.up {
    transform: rotate(-90deg);
}

.corporate-ladder > .arrow.down,
.corporate-ladder > .arrow.up-down {
    transform: rotate(90deg);
}

.corporate-ladder > .arrow.h1 {left: 30.5px}
.corporate-ladder > .arrow.h12 {left: calc((100% - 85px) / 12 + 30.5px)}
.corporate-ladder > .arrow.h2 {left: calc((100% - 85px) / 6 + 30.5px)}
.corporate-ladder > .arrow.h23 {left: calc((100% - 85px) / 4 + 30.5px)}
.corporate-ladder > .arrow.h3 {left: calc((100% - 85px) / 3 + 30.5px)}
.corporate-ladder > .arrow.h34 {left: calc((100% - 85px) / 12 * 5 + 30.5px)}
.corporate-ladder > .arrow.h4 {left: calc((100% - 85px) / 2 + 30.5px)}
.corporate-ladder > .arrow.h45 {left: calc((100% - 85px) / 12 * 7 + 30.5px)}
.corporate-ladder > .arrow.h5 {left: calc((100% - 85px) / 3 * 2 + 30.5px)}
.corporate-ladder > .arrow.h56 {left: calc((100% - 85px) / 4 * 3 + 30.5px)}
.corporate-ladder > .arrow.h6 {left: calc((100% - 85px) / 6 * 5 + 30.5px)}
.corporate-ladder > .arrow.h67 {left: calc((100% - 85px) / 12 * 11 + 30.5px)}
.corporate-ladder > .arrow.h7 {left: calc((100% - 85px) + 30.5px)}

.corporate-ladder > .arrow.v1 {top: 30.5px}
.corporate-ladder > .arrow.v12 {top: calc((100% - 63px) * 0.25 + 30.5px)}
.corporate-ladder > .arrow.v2 {top: calc((100% - 63px) / 2 + 30.5px)}
.corporate-ladder > .arrow.v23 {top: calc((100% - 63px) * 0.75 + 30.5px)}
.corporate-ladder > .arrow.v3 {top: calc(100% - 32.5px)}

.corporate-ladder.five-rows {
    height: 537px;
}

.corporate-ladder.five-rows > .person.h1 {left: 0}
.corporate-ladder.five-rows > .person.h2 {left: calc((100% - 85px) / 5)}
.corporate-ladder.five-rows > .person.h3 {left: calc((100% - 85px) * 0.4)}
.corporate-ladder.five-rows > .person.h4 {left: calc((100% - 85px) * 0.6)}
.corporate-ladder.five-rows > .person.h5 {left: calc((100% - 85px) * 0.8)}
.corporate-ladder.five-rows > .person.h6 {left: calc(100% - 85px)}

.corporate-ladder.five-rows > .person.v1 {top: 0}
.corporate-ladder.five-rows > .person.v2 {top: calc((100% - 63px) / 4)}
.corporate-ladder.five-rows > .person.v3 {top: calc(50% - 31.5px)}
.corporate-ladder.five-rows > .person.v4 {top: calc((100% - 63px) * 0.75)}
.corporate-ladder.five-rows > .person.v45 {top: calc((100% - 63px) * 0.875)} /* the only class allowing to place a person between rows */
.corporate-ladder.five-rows > .person.v5 {top: calc(100% - 63px)}

.corporate-ladder.five-rows > .arrow.h1 {left: 30.5px}
.corporate-ladder.five-rows > .arrow.h12 {left: calc((100% - 85px) * 0.1 + 30.5px)}
.corporate-ladder.five-rows > .arrow.h2 {left: calc((100% - 85px) * 0.2 + 30.5px)}
.corporate-ladder.five-rows > .arrow.h23 {left: calc((100% - 85px) * 0.3 + 30.5px)}
.corporate-ladder.five-rows > .arrow.h3 {left: calc((100% - 85px) * 0.4 + 30.5px)}
.corporate-ladder.five-rows > .arrow.h34 {left: calc((100% - 85px) / 2 + 30.5px)}
.corporate-ladder.five-rows > .arrow.h4 {left: calc((100% - 85px) * 0.6 + 30.5px)}
.corporate-ladder.five-rows > .arrow.h45 {left: calc((100% - 85px) * 0.7 + 30.5px)}
.corporate-ladder.five-rows > .arrow.h5 {left: calc((100% - 85px) * 0.8 + 30.5px)}
.corporate-ladder.five-rows > .arrow.h56 {left: calc((100% - 85px) * 0.9 + 30.5px)}
.corporate-ladder.five-rows > .arrow.h6 {left: calc((100% - 85px) + 30.5px)}

.corporate-ladder.five-rows > .arrow.v1 {top: 30.5px}
.corporate-ladder.five-rows > .arrow.v12 {top: calc((100% - 63px) * 0.125 + 30.5px)}
.corporate-ladder.five-rows > .arrow.v2 {top: calc((100% - 63px) * 0.25 + 30.5px)}
.corporate-ladder.five-rows > .arrow.v23 {top: calc((100% - 63px) * 0.375 + 30.5px)}
.corporate-ladder.five-rows > .arrow.v3 {top: calc((100% - 63px) / 2 + 30.5px)}
.corporate-ladder.five-rows > .arrow.v34 {top: calc((100% - 63px) * 0.625 + 30.5px)}
.corporate-ladder.five-rows > .arrow.v4 {top: calc((100% - 63px) * 0.75 + 30.5px)}
.corporate-ladder.five-rows > .arrow.v45 {top: calc((100% - 63px) * 0.875 + 30.5px)}
.corporate-ladder.five-rows > .arrow.v5 {top: calc(100% - 32.5px)}

.corporate-ladder-mobile {
    display: none;
    margin: 0 auto;
    max-width: 400px;
    padding: 20px;
    width: 100%;
}

.corporate-ladder-mobile select {
    outline: none;
}

.corporate-ladder-mobile select:hover,
.corporate-ladder-mobile select:active {
    outline: solid 1px #e53935;
}

#ladder {
    padding-top: 20px;
}

#ladder > div {
    background: transparent url('/images/icons/person.svg') no-repeat scroll center top;
    background-size: 28px 32px;
    color: #212121;
    font-size: 11px;
    font-weight: normal;
    min-height: 63px;
    line-height: 12px;
    padding-top: 39px;
    position: relative;
    text-align: center;
}

#ladder > div:first-of-type {
    background-image: url('/images/icons/person-red.svg');
    color: #e53935;
}

#ladder > div:first-of-type::after {
    content: ' (РЇ)';
}

#ladder > div + div {
    margin-top: 32px;
}

#ladder > div + div::before {
    background-color: #bababa;
    content: '';
    height: 24px;
    left: calc(50% - 1px);
    position: absolute;
    top: -32px;
    width: 2px;
}

#ladder > div + div::after {
    border: solid 2px transparent;
    border-bottom-color: #bababa;
    border-right-color: #bababa;
    content: '';
    height: 12px;
    left: calc(50% - 12px);
    position: absolute;
    top: -19px;
    transform: rotate(45deg);
    transform-origin: right bottom;
    width: 12px;
}

.ladder-hint {
    border: solid 3px #e53935;
    display: block;
    padding: 20px;
}

#empty-option {
    opacity: 0.5;
}

.corporate-ladder-mobile .select-placeholder {
    height: 42px;
    line-height: 42px;
    margin-top: -42px;
    opacity: 0.5;
    padding: 0 10px;
    pointer-events: none;
}

.positions-list {
    margin: 30px auto;
    max-width: 260px;
    width: 100%;
}

.positions-list .radiobutton {
    padding-top: 6px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.positions-list .radiobutton input {
    display: none;
}

.positions-list .radiobutton label {
    color: rgba(0,0,0,0.87);
    cursor: pointer;
    font-size: 14px;
    height: 18px;
    line-height: 18px;
    padding-left: 24px;
    position: relative;
}

.positions-list .radiobutton label::before {
    background-color: transparent;
    border: solid 2px #e53935;
    border-radius: 100%;
    box-sizing: border-box;
    content: '';
    height: 16px;
    left: 0;
    position: absolute;
    top: 0;
    width: 16px;
}

.positions-list .radiobutton label::after {
    background-color: #e53935;
    border-radius: 100%;
    content: '';
    height: 8px;
    left: 4px;
    opacity: 0;
    position: absolute;
    top: 4px;
    transition: opacity 0.15s ease-in-out;
    width: 8px;
}

.positions-list .radiobutton input:checked + label::after {
    opacity: 1;
}

.positions-list input[type="submit"] {
    background-color: #e53935;
    border: none;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    color: white;
    cursor: pointer;
    display: block;
    font-size: 12px;
    font-weight: bold;
    height: 36px;
    line-height: 36px;
    margin: 10px auto 0;
    outline: none !important;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.15s ease-in-out;
    width: 260px;
}

.positions-list input[type="submit"]:hover {
    background-color: white;
    color: #e53935;
}
.positions-list input[type="submit"]:disabled,
.positions-list input[type="submit"]:disabled:hover{
    background-color: #b3b1b1 !important;
    color: #918c8c !important;
}
/* --- tables --- */

.tn-table,
.tn-table tr,
.tn-table tr td {
    border: none;
    border-collapse: collapse;
}

.tn-table {
    float: left;
    width: auto;
}

.tn-table tr {
    /**/
}

.tn-table tr:nth-of-type(odd) {
    background-color: #efefef;
}

.tn-table tr td {
    color: #212121;
    font-size: 14px;
    font-weight: normal !important;
}

/* --- unordered list --- */

ul.tn-unordered-list {
    /**/
}

ul.tn-unordered-list li {
    font-size: 12px;
    padding-left: 30px;
}

ul.tn-unordered-list li::before {
    content: '\23FA';
    margin-right: 5px;
}

ul.tn-unordered-list.tn-unordered-list--icons li {
    height: 20px;
    line-height: 20px;
}

ul.tn-unordered-list.tn-unordered-list--icons li img {
    display: inline-block;
    height: 20px;
    margin-right: 5px;
    position: relative;
    top: 5px;
    width: 20px;
}

ul.tn-unordered-list.tn-unordered-list--icons li + li {
    margin-top: 10px;
}

/* --- search result page --- */

.search-result {
    margin: 40px auto;
    max-width: 90%;
    width: 100%;
}

.search-result h4 {
    line-height: 28px;
}

.search-result h4 > * {
    display: inline-block;
    vertical-align: top;
}

.search-result h4 > span {
    color: #8c8c8c;
    font-size: 14px;
    font-weight: bold;
    line-height: 28px;
    text-transform: uppercase;
}

.search-result h4 > span > span {
    color: #212121;
}

.search-result h4 img {
    height: 28px;
    margin-right: 12px;
    width: 28px;
}

.search-result ul {
    /**/
}

.search-result ul li {
    display: block;
}

.search-result ul li + li {
    margin-top: 12px;
}

.search-result ul li a {
    color: #e53935;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.143;
    text-decoration: underline;
}

.search-result ul li div {
    color: #212121;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.333;
    margin-top: 8px;
}

/* ---------- forum ---------- */

.forum .forum-thread {
    margin: 20px 0;
}

.forum .forum-thread time {
    font-size: 14px;
    font-weight: bold;
    height: 16px;
    line-height: 16px;
    padding-left: 24px;
    position: relative;
}

.forum .forum-thread time::before {
    border: solid 2px #37a447;
    border-radius: 100%;
    content: '';
    display: block;
    height: 16px;
    left: 0;
    position: absolute;
    top: 0;
    width: 16px;
}

.forum .forum-thread > div:first-of-type {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.143;
    margin: 5px 0;
}

.forum .forum-thread > div + div div {
    background: transparent none no-repeat scroll left center;
    background-size: 24px 24px;
    display: inline-block;
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    padding-left: 32px;
    vertical-align: top;
}

.forum .forum-thread > div + div div:first-of-type {
    background-image: url('/images/icons/forum-users.svg');
}

.forum .forum-thread > div + div div + div {
    background-image: url('/images/icons/forum-messages.svg');
    margin-left: 20px;
}

.forum .baba {
    background: transparent url('/images/baba.png') no-repeat scroll right bottom;
    margin-bottom: 0;
}

/* ---------- forum thread ---------- */

.forum-thread-block {
    /**/
}

.forum-thread-item {
    margin: 20px 0;
}

.forum-thread-item__head {
    height: 36px;
}

.forum-thread-item__head > * {
    display: inline-block;
    vertical-align: top;
}

.forum-thread-item__head > img {
    height: 36px;
    width: 36px;
}

.forum-thread-item__head > div {
    margin-left: 8px;
}

.forum-thread-item__head > div > div {
    font-size: 14px;
    font-weight: bold;
}

.forum-thread-item__head > div > time {
    font-size: 12px;
    line-height: 1.333;
    margin-top: -4px;
}

.forum-thread-item__text {
    font-size: 12px;
    margin-top: 8px;
}

.forum-thread-block .row {
    padding-top: 20px;
}

.forum-write {
    background-color: #f5f5f5;
    border: solid 1px rgba(0,0,0,0.2);
    box-shadow: rgba(0, 0, 0, 0.2) 0 4px 8px 0;
    padding: 16px 32px 20px;
    position: relative;
}

.forum-write h3 {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.125;
    margin: 0;
    text-align: center;
}

.forum-write textarea {
    background-color: white;
    border-radius: 6px;
    font-size: 12px;
    height: 150px;
    margin: 10px 0;
    outline: none;
    padding: 4px;
    resize: none;
}

.forum-write input {
    background-color: #e53935;
    border: none;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    display: block;
    font-size: 12px;
    font-weight: bold;
    height: 44px;
    line-height: 44px;
    margin: 0 auto;
    max-width: 260px;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.15s ease-in-out;
    width: 100%;
}

.forum-write input:hover {
    background-color: #e8e8e8;
    color: #8c8c8c;
}

/* ---------- accident map ---------- */

.accident-map {
    /**/
}

.accident-map__top-text {
    padding: 30px 0;
}

.accident-map__image {
    position: relative;
}

.accident-map__image img {
    height: auto;
    width: 100vw;
}

.accident-map__image a {
    background: transparent url('/images/icons/placemark.svg') no-repeat scroll center;
    background-size: 48px;
    color: white;
    cursor: pointer;
    display: block;
    height: 48px;
    margin-left: -24px;
    margin-top: -48px;
    padding-top: 6px;
    position: absolute;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: 48px;
}

.accident-map ul {
    columns: 2;
    counter-reset: x;
    margin-bottom: 30px;
    margin-top: 30px;
}

.accident-map ul li {
    counter-increment: x 1;
    height: 40px;
    margin: 0 0 20px;
    padding-left: 60px;
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;
}

.accident-map ul li::before {
    background-color: #37a447;
    content: '';
    display: block;
    height: 40px;
    left: 0;
    position: absolute;
    top: 0;
    width: 40px;
}

.accident-map ul li::after {
    background-color: transparent;
    border: solid 2px white;
    border-radius: 100%;
    box-sizing: border-box;
    color: white;
    content: counter(x);
    display: block;
    font-size: 16px;
    height: 36px;
    left: 2px;
    line-height: 32px;
    position: absolute;
    text-align: center;
    top: 2px;
    width: 36px;
}

.accident-map ul li a {
    color: #37a447;
    font-size: 16px;
    line-height: 24px;
    text-decoration: underline;
    text-transform: uppercase;
}

/* ----------accident info ---------- */

.accident-info {
    /**/
}

.accident-info table {
    border: none;
    border-collapse: collapse;
}

.accident-info table thead th {
    font-weight: bold;
}

.accident-info table tbody td {
    font-weight: normal;
}

.accident-info table span[data-expand] {
    color: #e53935;
    cursor: pointer;
    text-decoration: underline;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.accident-info table tbody tr:nth-of-type(even) {
    display: none;
}

.accident-info table tbody tr.expanded + tr {
    display: table-row;
}

.accident-info table tbody tr:nth-of-type(4n+1) {
    background-color: #f1f1f1;
}

/* ----- write message page ----- */

.write-message .container {
    background: transparent url('/images/background.jpg') no-repeat scroll center;
    background-size: cover;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0;
    max-width: none;
    min-height: 465px;
    width: 100%;
}

.write-message form,
.message-successfully-sent {
    background-color: #f5f5f5;
    border-radius: 6px;
    overflow: hidden;
    margin: 20px auto;
    max-width: 480px;
    padding: 16px 32px;
    width: 100%;
}

.write-message form div {
    font-size: 14px;
    line-height: 1.188;
    margin-bottom: 10px;
}

.write-message form label {
    color: #212121;
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
}

.write-message form label[for="type"] {
    display: inline-block;
}

.write-message form label.for-input {
    color: #212121 !important;
}

.write-message form label + select {
    display: inline-block;
    outline: none;
    width: 200px;
}

.write-message form h2 {
    color: #212121;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.125;
    margin: 0 0 10px;
    text-align: center;
}

.write-message form textarea {
    background-color: white;
    border: solid 1px #ebebeb;
    border-radius: 2px;
    font-size: 13px;
    margin: 0 0 10px;
    min-height: 150px;
    outline: none;
    padding: 5px 10px;
    resize: none;
}

.write-message form input.browser-default {
    background-color: white !important;
    border: solid 1px #ebebeb;
    border-radius: 2px;
    box-shadow: none;
    color: #212121 !important;
    font-size: 13px;
    font-weight: normal;
    margin: 0 0 10px;
    outline: none;
    padding: 5px 10px;
    text-align: left;
    text-transform: none;
    width: 100%;
}

.write-message form input,
.message-successfully-sent a.button-ok {
    background-color: #e53935;
    border: none;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 4px 8px 0;
    color: white;
    cursor: pointer;
    display: block;
    font-size: 12px;
    font-weight: 700;
    margin: 0 auto;
    padding: 15px;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.15s ease-in-out;
    width: 260px;
}

.write-message form input:hover,
.message-successfully-sent a.button-ok:hover {
    background-color: #e8e8e8;
    color: #8c8c8c;
}

.message-successfully-sent img {
    display: block;
    height: 96px;
    margin: 0 auto;
    width: 96px;
}

.message-successfully-sent div {
    color: #37a447;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    margin: 0 auto 24px;
    text-align: center;
    width: 160px;
}

/* ----- messaging history page ----- */

.messaging-history .container {
    padding: 20px 0;
}

.messaging-history .message-item {
    padding: 20px 15px;
    position: relative;
}

.messaging-history .message-item .message-item-arrow {
    background: transparent url('/images/icons/message-item.svg') no-repeat scroll center;
    background-size: contain;
    height: 28px;
    position: absolute;
    right: 15px;
    top: 15px;
    transform: rotate(-90deg);
    width: 28px;
}

.messaging-history .message-item:nth-of-type(even) {
    background-color: #f4f4f4;
}

.messaging-history .message-item > * {
    cursor: pointer;
    display: block;
}

.messaging-history .message-item > time {
    color: #a3a3a3;
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;
    padding-left: 24px;
    position: relative;
}

.messaging-history .message-item.unread > time {
    color: #212121;
    font-weight: bold;
}

.messaging-history .message-item > time::before {
    border: solid 2px #bababa;
    border-radius: 100%;
    box-sizing: border-box;
    content: '';
    display: block;
    height: 14px;
    left: 0;
    position: absolute;
    top: 1px;
    width: 14px;
}

.messaging-history .message-item.unread > time::before {
    background-color: #37a447;
    border-color: #37a447;
}

.messaging-history .message-item > b {
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;
    margin: 8px 0;
}

.messaging-history .message-item.unread > b {
    font-weight: bold;
}

.messaging-history .message-item > div {
    font-size: 12px;
    line-height: 1.333;
}

.message-text {
    background-color: #f5f5f5;
    box-shadow: rgba(0, 0, 0, 0.2) 0 4px 8px 0;
    margin: 20px auto 0;
    padding: 20px 40px;
    position: relative;
    transition: opacity 0.15s ease-in-out;
    width: 480px;
}

.message-text__date {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.125;
    margin-bottom: 10px;
}

.message-text__title {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.143;
}

.message-text__text {
    font-size: 12px;
    line-height: 1.333;
}

.message-text__close {
    background: transparent url('/images/icons/close-msg.svg') no-repeat scroll center;
    background-size: contain;
    cursor: pointer;
    height: 32px;
    position: absolute;
    right: 10px;
    top: 10px;
    transition: transform 0.15s ease-in-out;
    width: 32px;
}

.message-text__close:hover {
    transform: rotate(90deg);
}

.message-filter {
    background-color: #ebebeb;
    display: flex;
    flex-direction: row;
    height: 48px;
    justify-content: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.message-filter input {
    display: none;
}

.message-filter label {
    background: transparent none no-repeat scroll center top;
    background-size: 24px 24px;
    border-bottom: solid 2px transparent;
    box-sizing: border-box;
    color: #212121;
    cursor: pointer;
    display: block;
    font-size: 8px;
    height: 48px;
    letter-spacing: 1px;
    line-height: 24px;
    padding-top: 24px;
    text-align: center;
    text-transform: uppercase;
    width: 92px;
}

label[for="f-all"] {
    background-image: url('/images/icons/filter-all__gray.svg');
}

label[for="f-read"] {
    background-image: url('/images/icons/filter-read__gray.svg');
}

label[for="f-unread"] {
    background-image: url('/images/icons/filter-unread__gray.svg');
}

.message-filter #f-all:checked ~ label[for="f-all"] {
    background-image: url('/images/icons/filter-all.svg');
}

.message-filter #f-read:checked ~ label[for="f-read"] {
    background-image: url('/images/icons/filter-read.svg');
}

.message-filter #f-unread:checked ~ label[for="f-unread"] {
    background-image: url('/images/icons/filter-unread.svg');
}

.message-filter #f-all:checked ~ label[for="f-all"],
.message-filter #f-read:checked ~ label[for="f-read"],
.message-filter #f-unread:checked ~ label[for="f-unread"] {
    border-bottom-color: #e53935;
    color: #e53935;
}

.message-items[data-filter="read"] .message-item.unread,
.message-items[data-filter="unread"] .message-item:not(.unread) {
    display: none;
}

.messaging-history .message-items[data-filter] .message-item {
    background-color: white;
}

/* ----- preloader ----- */

.preloader {
    align-items: center;
    background-color: rgba(0,0,0,0.75);
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 100000;
}

html[data-loading] {
    overflow: hidden;
}

html[data-loading] .preloader {
    display: flex;
}

.preloader .md-preloader{
    width: 75px;
    height: 75px;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
}
.preloader .md-preloader svg circle {
    stroke: #e53935;
}

/****************************** MEDIA ******************************/

@media screen and (max-width: 1199px) {
    .learning__spec .text-article,
    .learning__spec .text-list {
        max-width: 90%;
    }
}

@media screen and (max-width: 999px) {
    .corporate-ladder {
        max-width: calc(100% - 50px);
    }
}

@media screen and (max-width: 799px) {
    .corporate-ladder {
        height: 700px;
        max-width: 400px;
    }

    .corporate-ladder > .person.h1 {top: 0}
    .corporate-ladder > .person.h2 {top: calc((100% - 63px) / 6)}
    .corporate-ladder > .person.h3 {top: calc((100% - 63px) / 3)}
    .corporate-ladder > .person.h4 {top: calc(50% - 31.5px)}
    .corporate-ladder > .person.h5 {top: calc((100% - 63px) / 3 * 2)}
    .corporate-ladder > .person.h6 {top: calc((100% - 63px) / 6 * 5)}
    .corporate-ladder > .person.h7 {top: calc(100% - 63px)}

    .corporate-ladder > .person.v1 {left: 0}
    .corporate-ladder > .person.v2 {left: calc(50% - 42.5px)}
    .corporate-ladder > .person.v3 {left: calc(100% - 85px)}

    .corporate-ladder > .arrow.h1 {top: 30.5px}
    .corporate-ladder > .arrow.h12 {top: calc((100% - 63px) / 12 + 30.5px)}
    .corporate-ladder > .arrow.h2 {top: calc((100% - 63px) / 6 + 30.5px)}
    .corporate-ladder > .arrow.h23 {top: calc((100% - 63px) / 4 + 30.5px)}
    .corporate-ladder > .arrow.h3 {top: calc((100% - 63px) / 3 + 30.5px)}
    .corporate-ladder > .arrow.h34 {top: calc((100% - 63px) / 12 * 5 + 30.5px)}
    .corporate-ladder > .arrow.h4 {top: calc((100% - 63px) / 2 + 30.5px)}
    .corporate-ladder > .arrow.h45 {top: calc((100% - 63px) / 12 * 7 + 30.5px)}
    .corporate-ladder > .arrow.h5 {top: calc((100% - 63px) / 3 * 2 + 30.5px)}
    .corporate-ladder > .arrow.h56 {top: calc((100% - 63px) / 4 * 3 + 30.5px)}
    .corporate-ladder > .arrow.h6 {top: calc((100% - 63px) / 6 * 5 + 30.5px)}
    .corporate-ladder > .arrow.h67 {top: calc((100% - 63px) / 12 * 11 + 30.5px)}
    .corporate-ladder > .arrow.h7 {top: calc((100% - 63px) + 30.5px)}

    .corporate-ladder > .arrow.v1 {left: 30.5px}
    .corporate-ladder > .arrow.v12 {left: calc((100% - 85px) * 0.25 + 30.5px)}
    .corporate-ladder > .arrow.v2 {left: calc((100% - 85px) / 2 + 30.5px)}
    .corporate-ladder > .arrow.v23 {left: calc((100% - 85px) * 0.75 + 30.5px)}
    .corporate-ladder > .arrow.v3 {left: calc(100% - 54.5px)}

    .corporate-ladder > .arrow.right {
        transform: rotate(90deg);
    }

    .corporate-ladder > .arrow.right-down {
        transform: rotate(45deg);
    }

    .corporate-ladder > .arrow.right-up {
        transform: rotate(135deg);
    }

    .corporate-ladder > .arrow.up {
        transform: rotate(180deg);
    }

    .corporate-ladder > .arrow.down,
    .corporate-ladder > .arrow.up-down {
        transform: none;
    }

    .corporate-ladder.five-rows {
        display: none;
    }

    .corporate-ladder-mobile {
        display: block;
    }
}

@media screen and (max-width: 444px) {
    .corporate-ladder {
        max-width: 90%;
    }
}

@media screen and (max-width: 992px) {
    .start-page .container .user {
        margin-bottom: 20px;
        text-align: center;
    }

    .start-page .container .user > div {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle;
    }

    .start-page .container .user__info {
        text-align: left;
    }

    .start-page .container .user > div + div {
        margin-left: 20px;
    }

    .welcome .row .col:first-of-type {
        text-align: center;
    }

    .test-page .row > .col:first-of-type {
        display: block;
        min-height: 0;
        padding-bottom: 30px;
    }

    .test-answers .row,
    .statistics .row,
    .rating .row {
        background-image: none;
        min-height: 0;
    }

    .rating .container {
        margin: 0 -10px;
        width: calc(100% + 20px);
    }

    .test-info {
        border: none;
        margin: 0;
        padding: 0 0 0 44px;
    }

    .statistics ul li > b {
        text-overflow: initial;
        white-space: initial;
    }
}

@media screen and (max-width: 849px) {
    .buttons.buttons__three {
        padding: 1px 0 !important;
    }

    .buttons.buttons__three .btn {
        display: block;
        margin: 16px auto;
        width: 260px;
    }
}

@media screen and (max-width: 719px) {
    .test .test-page .container,
    .test-result .container {
        max-width: calc(100% - 40px);
    }

    .test .test-page p {
        padding-left: 28px;
    }

    .test-page p:first-of-type::before {
        left: -10px;
    }

    .test .test-page a.btn {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 659px) {
    .search-result h4 {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        line-height: 1.143;
    }

    .search-result h4 > span {
        line-height: 1.143;
    }
}

@media screen and (max-width: 648px) {
    .start-page .user__photo,
    .start-page .user__photo img {
        height: 23.125vw;
        width: 23.125vw;
    }
    .accident-map ul {
        columns: 1;
    }
}

@media screen and (max-height: 640px) {
    .container > .row:first-of-type {
        margin-bottom: 0;
    }
    .owl-carousel .owl-dots {
        margin-top: 0;
    }
}

@media screen and (max-width: 620px) {
    .start-page .container-wrapper {
        background-image: none;
        text-align: center;
    }
}

@media screen and (max-width: 600px) {
    .footer .container {
        width: calc(100% - 40px);
    }

    .footer p, .footer small {
        max-width: calc(100% - 40px);
    }

    .owl-carousel .item img {
        height: 64px;
        width: 64px;
    }

    .start-page .container .user {
        padding-left: 0;
        padding-right: 0;
    }

    .start-page .container .user .user__photo {
        border-radius: 0;
        width: 32%;
    }

    .start-page .container .user .user__info {
        margin-left: 0;
        width: 66%;
    }

    .profile .container {
        margin-bottom: 20px;
        margin-top: 4px;
    }

    .profile .password {
        background-color: white;
        margin: 0 auto;
        min-width: 260px;
        padding: 0;
        width: calc(100% - 60px);
    }

    .profile .password button {
        margin: 0 auto;
        width: 260px;
    }

    .profile .about {
        margin: 0 auto;
        min-width: 260px;
        width: calc(100% - 60px);
    }

    .profile .container .row {
        margin-bottom: 0;
    }

    .profile .container .col {
        padding: 0;
    }

    .profile .container form label {
        left: -10px;
        position: relative;
    }

    .profile .about textarea {
        height: 320px;
    }

    .footer p,
    .footer h6,
    .footer .col > div {
        font-size: 12px;
        line-height: 20px;
    }

    .start-page p:not(.big):not(.mid) {
        display: inline;
    }

    .start-page .container-wrapper p + a {
        margin-top: 20px;
    }

    .sidenav li {
        line-height: 44px;
    }

    .sidenav li > a {
        height: 44px;
        line-height: 44px;
    }

    .sidenav a img, .sidenav label img {
        margin: 8px 8px 0 0;
    }

    .sidenav a, .sidenav label {
        padding: 0 12px !important;
    }

    .rating-and-statisctics {
        display: block;
    }

    .test-result .col:first-of-type {
        min-height: 360px;
    }

    .test-questions {
        max-width: calc(100% - 40px);
    }

    .forum .baba {
        background-image: none;
    }

    .forum-write {
        position: static;
    }
}

@media screen and (max-width: 599px) {
    .buttons:not(.buttons__three) {
        padding: 10px 0 !important;
    }

    .buttons:not(.buttons__three) .btn {
        display: block;
        margin: 10px auto;
        width: 260px;
    }
}

@media screen and (max-width: 520px) {
    .welcome .btn {
        display: block;
        margin-left: auto !important;
        margin-right: auto;
        margin-top: 12px;
        max-width: 240px;
    }
}

@media screen and (max-width: 499px) {
    .learning .area {
        width: 100% !important;
    }

    .test .test-page .container {
        max-width: calc(100% - 40px);
    }
}

@media screen and (max-width: 399px) {
    .start-page .container {
        width: calc(100% - 10px);
    }

    .start-page .col.l2 > a {
        border-radius: 6px;
        height: 80px;
        padding-top: 16px;
    }

    .start-page .col.l2 > a div {
        margin-top: 6px;
        padding: 0;
    }

    .start-page .col.l2 > a img {
        height: 28px;
        width: 28px;
    }

    .start-page .col.l2 > a div {
        font-size: 8px;
        letter-spacing: 2px;
        line-height: 12px;
    }

    .start-page .col.l9.tiles .col .new-messages {
        right: calc(50% - 25px);
        top: calc(50% - 28px);
    }

    .start-page .container .user .user__info {
        letter-spacing: 1px;
    }

    .testing .container {
        max-width: calc(100% - 20px);
    }

    .testing .testing-item {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 360px) {
    .thrifty .container {
        padding: 0 calc(50vw - 148px) 20px;
    }

    .thrifty .container a {
        margin-top: 20px;
    }

    .safety .container {
        padding: 0 calc(50vw - 148px) 20px;
    }

    .safety .container a {
        margin-top: 20px;
    }

    .testing__label span {
        font-size: 12px;
        line-height: 1.25;
        text-align: left;
        width: calc(100% - 76px);
    }
}

@media screen and (max-width: 340px) {
    .test-question__buttons a {
        min-width: 100px;
    }

    .test-question__buttons a + a {
        margin-left: 15px;
    }

    .forum .forum-thread > div + div div + div {
        margin-left: 10px;
    }
}

.siz_vidget{margin-top:20px;margin-bottom:20px;}
.siz_vidget .picture{ height: 600px; background: url('/images/siz.png') no-repeat center center; background-size: contain;}
.siz_vidget .picture .layer{background: no-repeat center center;background-size: contain;	position: absolute;}
.siz_vidget .items{ border-left: 1px solid #ccc;}
.siz_vidget .items .siz_item img{width:50%;}
.siz_vidget .items .siz_item a{height:200px; display: block; color:#222; text-decoration: none; border:3px solid transparent;margin-bottom:15px;}
.siz_vidget .items .siz_item a:hover{border-color:#ccc;}
.siz_vidget .items .siz_item a.active{ border-color:#489642; background: url('/images/tick-inside-circle.svg') no-repeat; background-size: 40px 40px; background-position: 10px 10px;}
