.error {
    border:1px solid red !important;
}

.learning .col {
    min-height: 305px;
}

.learning__spec .text-article ol li {
    list-style-type: decimal;
}

.learning__spec .text-article ul {
    padding-left: 40px;
}

.learning__spec .text-article ul li {
    list-style-type: circle;
}

.learning__spec .text-article li::before {
    content: none;
}

.list-of-cores td img {
    max-width: 100%!important;
    width: auto!important;
    height: auto!important;
}

.list-of-cores th, .list-of-cores td {
    border: 1px solid black;
}

.list-of-cores th, .list-of-cores td {
    vertical-align: top;
}